import revive_payload_client_x7nQj0Ph5R from "/app/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_eslint@8.57.1_rollup@4.30.1_typescript@5.7.2_vite@6.0.7_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LgNTJagw0w from "/app/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_eslint@8.57.1_rollup@4.30.1_typescript@5.7.2_vite@6.0.7_vue-tsc@2.2.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_529stsgc5e from "/app/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_eslint@8.57.1_rollup@4.30.1_typescript@5.7.2_vite@6.0.7_vue-tsc@2.2.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Dv7a4oy4d0 from "/app/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_eslint@8.57.1_rollup@4.30.1_typescript@5.7.2_vite@6.0.7_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_o5qbOW7mUa from "/app/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_eslint@8.57.1_rollup@4.30.1_typescript@5.7.2_vite@6.0.7_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_UvrkNGRDtu from "/app/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_eslint@8.57.1_rollup@4.30.1_typescript@5.7.2_vite@6.0.7_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ANuTvynyU4 from "/app/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_eslint@8.57.1_rollup@4.30.1_typescript@5.7.2_vite@6.0.7_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_Lyuo9eGZk7 from "/app/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_eslint@8.57.1_rollup@4.30.1_typescript@5.7.2_vite@6.0.7_vue-tsc@2.2.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_97xyvCpDeX from "/app/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_rollup@4.30.1_vue@3.5.13/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_k4q335vUMI from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.15.1_rollup@4.30.1_vue@3.5.13/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import i18n_B4jQuSV8o9 from "/app/node_modules/.pnpm/@nuxtjs+i18n-edge@8.3.1-28565891.16f9309_rollup@4.30.1_vue@3.5.13/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/i18n.mjs";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_x7nQj0Ph5R,
  unhead_LgNTJagw0w,
  router_529stsgc5e,
  payload_client_Dv7a4oy4d0,
  navigation_repaint_client_o5qbOW7mUa,
  check_outdated_build_client_UvrkNGRDtu,
  chunk_reload_client_ANuTvynyU4,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Lyuo9eGZk7,
  plugin_97xyvCpDeX,
  plugin_k4q335vUMI,
  i18n_B4jQuSV8o9,
  plugin_wy0B721ODc,
  sentry_client_shVUlIjFLk
]