<template>
  <div
    class="flex flex-col sm:flex-row gap-2 md:gap-4 sm:h-[264px] lg:h-[420px] mt-4 sm:mt-6"
  >
    <div class="sm:w-full rounded-lg relative">
      <NuxtImg
        :height="'auto'"
        :preload="true"
        :src="String(mainMedia?.src?.mobile)"
        :alt="mainMedia?.alt"
        aspect-ratio="auto"
        loading="eager"
        class="sm:hidden w-full h-full object-cover rounded-lg"
        sizes="xs:100vw sm:100vw md:100vw lg:910px xl:910px xxl:910px"
      >
      </NuxtImg>
      <NuxtImg
        :height="'auto'"
        :preload="true"
        :src="String(mainMedia?.src?.desktop)"
        :alt="mainMedia?.alt"
        aspect-ratio="auto"
        loading="eager"
        class="hidden sm:block w-full h-full object-cover rounded-lg"
        sizes="xs:100vw sm:100vw md:100vw lg:910px xl:910px xxl:910px"
      >
      </NuxtImg>

      <div
        class="absolute bottom-0 flex flex-col justify-center px-4 pb-4 sm:px-12 sm:h-full"
      >
        <h1 class="text-h3 font-semibold">{{ mainMedia.title }}</h1>
        <h2 class="text-base font-medium my-4">{{ mainMedia.subtitle }}</h2>
        <NuxtLazyHydrate when-idle>
          <FederatedSearchLoadingPlaceholder v-show="!isMounted" />
        </NuxtLazyHydrate>
        <NuxtLazyHydrate when-idle>
          <FederatedSearch
            v-show="isMounted"
            element-id="banner-autocomplete"
          />
        </NuxtLazyHydrate>
      </div>
    </div>
    <div class="secondary-cards-container">
      <div
        v-for="(media, index) in secondaryMedia?.slice(0, 4)"
        :key="index"
        class="rounded-lg cursor-pointer group overflow-hidden w-[125px] h-[125px] sm:h-auto lg:w-[202px] md:block relative"
        :class="{
          'sm:row-span-2': index === 2,
          'lg:h-[202px] sm:hidden': index !== 2,
        }"
      >
        <component
          :is="media.url ? 'a' : 'div'"
          :href="media.url"
          class="h-full"
        >
          <NuxtImg
            :height="'auto'"
            :preload="true"
            :src="String(media?.src?.mobile)"
            :alt="media.alt"
            aspect-ratio="auto"
            loading="lazy"
            class="h-full w-full object-cover sm:hidden"
            sizes="xs:100vw sm:100vw md:100vw lg:910px xl:910px xxl:910px"
          />
          <NuxtImg
            :height="'auto'"
            :preload="true"
            :src="String(media?.src?.desktop)"
            :alt="media.alt"
            aspect-ratio="auto"
            loading="lazy"
            class="h-full w-full object-cover hidden sm:block"
            sizes="xs:100vw sm:100vw md:100vw lg:910px xl:910px xxl:910px"
          />
          <div
            class="bg-blend-hard-light bg-gradient-to-t from-stone-700 to-transparent absolute bottom-0 left-0 w-full h-full overflow-hidden opacity-30 md:opacity-0 group-hover:opacity-10 transition-all duration-300"
          ></div>
          <div
            class="title absolute bottom-0 left-0 p-2 w-full font-semibold text-white text-sm"
          >
            {{ media.text }}
          </div>
        </component>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const isMounted = ref(false)
onMounted(() => {
  isMounted.value = true
})

const homePageGalleryData = inject('homePageGalleryData') as ComputedRef<any>
const bannerData = computed(
  () => homePageGalleryData.value?.data?.attributes?.banner
)
const mainMedia = computed(() => {
  const mainMediaBlock = bannerData.value?.mainMediaBlock
  const alt = strapiImageGetters.getAlternativeText(mainMediaBlock?.media?.data)

  return {
    src: {
      mobile:
        strapiImageGetters.getImageUrl(
          mainMediaBlock?.mediaMobile?.data,
          'small'
        ) ||
        strapiImageGetters.getImageUrl(mainMediaBlock?.media?.data, 'large'),
      desktop: strapiImageGetters.getImageUrl(
        mainMediaBlock?.media?.data,
        'large'
      ),
    },
    alt,
    title: mainMediaBlock?.title,
    subtitle: mainMediaBlock?.subtitle,
  }
})

const secondaryMedia = computed(() => {
  if (!bannerData.value?.secondaryMediaBlocks) return []
  return bannerData.value?.secondaryMediaBlocks
    .filter((mediaBlock) =>
      mediaBlock?.media?.data?.attributes?.mime?.includes('image')
    )
    .map((mediaBlock) => ({
      src: {
        mobile:
          strapiImageGetters.getImageUrl(mediaBlock?.mediaMobile?.data) ||
          strapiImageGetters.getImageUrl(mediaBlock?.media?.data, 'large'),
        desktop: strapiImageGetters.getImageUrl(
          mediaBlock?.media?.data,
          'large'
        ),
      },
      alt: strapiImageGetters.getAlternativeText(mediaBlock?.media?.data),
      text: mediaBlock?.title,
      url: mediaBlock?.url,
    }))
})
</script>
<style lang="postcss" scoped>
.secondary-cards-container {
  @apply grid grid-rows-1 sm:grid-rows-2 grid-flow-col gap-4;
  @apply overflow-x-auto;
  @media (min-width: 640px) {
    overflow-x: unset;
  }
}

.title {
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: -10px;
    background: linear-gradient(-180deg, transparent, rgba(0, 0, 0, 0.7));
    z-index: -1;
  }
}
</style>
