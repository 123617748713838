<template>
  <NuxtImg ref="intersectionWrapperRef" :src="computedSrc" :data-src="src" />
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useIntersectionObserver } from '~/composables/useIntersectionObserver'

const props = withDefaults(
  defineProps<{
    src?: string
    fallbackSrc?: string
  }>(),
  {
    fallbackSrc:
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='80' height='25'><defs><linearGradient id='g1'><stop stop-color='#F5F6F7'/><stop offset='.983' stop-color='#ECEDF0'/><stop offset='1' stop-color='#ECEDF0'/></linearGradient></defs><rect width='100%' height='100%' fill='url(#g1)'/></svg>",
  }
)

const intersectionWrapperRef = ref(undefined)
const targetWasEverVisible = ref(false)

const { intersectionHappened, stopObserving } = useIntersectionObserver(
  intersectionWrapperRef
)

const computedSrc = computed(() =>
  props.src && targetWasEverVisible.value ? props.src : props.fallbackSrc
)

watchEffect(() => {
  if (intersectionHappened.value) {
    targetWasEverVisible.value = true
  }
})

watchEffect(() => {
  if (props.src && targetWasEverVisible.value) {
    stopObserving()
  }
})

onUnmounted(() => {
  stopObserving()
})
</script>
