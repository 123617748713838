import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 30 30"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g filter=\"url(#a)\"><path fill=\"none\" stroke=\"#D1D5DB\" stroke-width=\"2\" d=\"M22.225 6.362c-2.575-1.755-5.754-.936-7.47 1.073-1.716-2.01-4.895-2.838-7.47-1.073-1.364.936-2.222 2.516-2.281 4.183-.136 3.784 3.218 6.816 8.337 11.468l.098.088a1.946 1.946 0 0 0 2.623-.01l.107-.098c5.12-4.641 8.464-7.674 8.337-11.457a5.33 5.33 0 0 0-2.281-4.174Z\"></path></g><defs><filter id=\"a\" width=\"41.51\" height=\"39.208\" x=\"-2\" y=\"-1.604\" color-interpolation-filters=\"sRGB\" filterUnits=\"userSpaceOnUse\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"></feFlood><feColorMatrix in=\"SourceAlpha\" result=\"hardAlpha\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\"></feColorMatrix><feOffset dx=\"4\" dy=\"4\"></feOffset><feGaussianBlur stdDeviation=\"3\"></feGaussianBlur><feComposite in2=\"hardAlpha\" operator=\"out\"></feComposite><feColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0\"></feColorMatrix><feBlend in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_2007_63\"></feBlend><feBlend in=\"SourceGraphic\" in2=\"effect1_dropShadow_2007_63\" result=\"shape\"></feBlend></filter></defs>", 2)
  ])))
}
export default { render: render }