<template>
  <div class="relative z-[200]">
    <Overlay :visible="isOpen" @click="close" />
    <Drawer
      ref="dateRequestSidebarRef"
      class="wishlist-sidebar__aside"
      :is-open="isOpen"
      @click:close="close"
    >
      <template #header>
        <LazyWishlistSidebarHeader v-if="isOpen" />
      </template>
      <div class="flex flex-col justify-between flex-1 px-2">
        <LazyProductShowcase
          v-if="isOpen"
          data-test-id="wishlist-product-showcase-section"
          :disable-send-event-to-algolia="true"
          :products="wishlistItems"
          image-loading="lazy"
          :show-items-on-desktop="999"
          :tags="[]"
          @click:product-card="close"
        />
      </div>
    </Drawer>
  </div>
</template>

<script lang="ts" setup>
const { isOpen, close } = useWishlistSidebarUiState()
const { wishlist } = useWishlist()

const wishlistItems = computed(() =>
  wishlist.value.items.map((item) => item.object)
)
</script>

<style lang="postcss" scoped>
.wishlist-sidebar__aside {
  @apply w-full sm:w-[600px] px-0 pt-0 pb-0 flex flex-col h-[100dvh];
}

:deep(div.product-card > *) {
  @apply w-full;
}

:deep(div.product-card__title) {
  @apply text-sm;
}

:deep(div.product-showcase-cards-container) {
  @apply grid-cols-1 md:grid-cols-2 gap-2;
}
</style>
