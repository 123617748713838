import * as qs from 'qs'
import type { UseFetchOptions } from 'nuxt/app'

export const useSubscribeEmailToEventUpdates = () => {
  /** Wrapper for API requests */
  const error = ref(null)
  const subscribeEmailToEventUpdates = async ({
    permalink,
    email,
  }: {
    permalink: string
    email: string
  }) => {
    // Logger.debug(`useSubscribeEmailToEventUpdates/${id}/subscribeEmailToEventUpdates`, id)

    try {
      loading.value = true
      await $fetch(`v1/store/events/${permalink}/subscribe`, {
        method: 'POST',
        body: { email },
      })

      error.value = null
    } catch (err) {
      error.value = err?.response?.data || err
      // Logger.error(`useSubscribeEmailToEventUpdates/${id}/subscribeEmailToEventUpdates`, err)
    } finally {
      loading.value = false
    }
  }

  return {
    subscribeEmailToEventUpdates,
    error,
  }
}

export const useFetchEventByPermalink = <T>(
  permalink: string,
  options?: UseFetchOptions<T>
) =>
  useKftApiFetch<T>(
    `v1/store/events/${permalink}?${qs.stringify(
      {
        include: ['address.locality', 'supplier', 'reviewsNew'],
      },
      { arrayFormat: 'comma' }
    )}`,
    options
  )

export const useFetchSupplierEvents = <T>(
  supplierId: string,
  embeddedPublished?: boolean,
  orderByProperty?: string,
  options?: UseFetchOptions<T>
) => {
  const paramsObj = {
    limit: '50',
    include: ['categories'],
    ...(orderByProperty && { orderBy: orderByProperty }),
    search: `supplier_id:${supplierId};hasEvent:false${embeddedPublished ? ';embeddedPublished:true' : ''}`,
  }

  const url = `v1/store/events?${qs.stringify(paramsObj, {
    // encodeValuesOnly: true, // prettify URL
    encode: false,
    arrayFormat: 'comma',
  })}`
  return useKftApiFetch(url, options)
}

export const useFetchSimilarEvents = <T>(
  partnerPermalink: string,
  options?: UseFetchOptions<T>
) => {
  const paramsObj = {
    orderBy: 'distance',
    sortedBy: 'asc',
    search: 'hasEvent:true',
  }

  const url = `v1/store/events/${partnerPermalink}/similar?${qs.stringify(
    paramsObj,
    {
      encode: false,
      arrayFormat: 'comma',
    }
  )}`
  return useKftApiFetch(url, options)
}

export const useFetchSimilarOnlineEvents = <T>(
  category: string,
  options?: UseFetchOptions<T>
) => {
  const paramsObj = {
    limit: '8',
    // search: { hasEvent: true, type: 'ONLINE', category },
    search: `hasEvent:true;type:ONLINE;category:${category}`,
  }

  const url = `v1/store/events?${qs.stringify(paramsObj, {
    encode: false,
    arrayFormat: 'comma',
  })}`
  return useKftApiFetch(url, options)
}
