<template>
  <div class="cart-sidebar relative z-[100]">
    <Overlay :visible="isCartSidebarOpen" @click="closeCartSidebar" />
    <Drawer
      ref="cartSidebarRef"
      class="card-sidebar__aside"
      :is-open="isCartSidebarOpen"
      data-test-id="cart-sidebar__aside"
      @click:close="closeCartSidebar"
    >
      <template #header>
        <LazyCartHeader v-if="isCartSidebarOpen" />
      </template>

      <div
        v-if="isCartSidebarOpen"
        class="flex flex-col justify-between flex-1 [&>*]:px-2"
      >
        <LazyCartBody />
        <LazyCartFooter />
      </div>
    </Drawer>
  </div>
</template>

<script lang="ts" setup>
const cartSidebarRef = ref(null)

const { toggleCartSidebar, isCartSidebarOpen, closeCartSidebar } = useUiState()

defineExpose({ toggleCartSidebar })
</script>
<style>
body.prevent-scroll {
  overflow: hidden;
}
</style>

<style lang="postcss" scoped>
.card-sidebar__aside {
  @apply w-full sm:w-[600px] px-0 pt-0 pb-0 flex flex-col h-[100dvh];
}
</style>
