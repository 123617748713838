import type { I18nHeadMetaInfo } from '@nuxtjs/i18n'
import { NUMBER_OF_EVENTS } from '~/composables/product/constants'

const useLayoutHead = () => {
  const { t, te, locale } = useI18n()

  const eventCount = computed(() =>
    formattedNumberOfEvents(locale.value, NUMBER_OF_EVENTS)
  )
  const { currentRoute } = useRouter()

  const routeName = computed(() => {
    return currentRoute.value.name?.toString().split('___')?.[0] || ''
  })

  const {
    public: { baseUrl },
  } = useRuntimeConfig()

  const i18nHead: Ref<I18nHeadMetaInfo> = useLocaleHead({
    addSeoAttributes: true,
  })

  const canonicalLink = i18nHead.value.meta?.find(
    (meta) => meta.name === 'canonical'
  )?.content

  const hasTitleTranslation = computed(() => {
    return te(`general.titles.${routeName.value}`)
  })

  const customMeta = {
    'og:site_name': t('general.titles.base'),
    'apple-mobile-web-app-title': t('general.titles.base'),
    ...(hasTitleTranslation.value && {
      title:
        t(`general.titles.${routeName.value}`, {
          eventCount: eventCount.value,
        }) +
        ' | ' +
        t('general.titles.base'),
      'og:title':
        t(`general.titles.${routeName.value}`, {
          eventCount: eventCount.value,
        }) +
        ' | ' +
        t('general.titles.base'),
    }),
    description:
      te(`general.descriptions.${routeName.value}`) &&
      t(`general.descriptions.${routeName.value}`, {
        eventCount: eventCount.value,
      }),
    'og:description':
      te(`general.descriptions.${routeName.value}`) &&
      t(`general.descriptions.${routeName.value}`, {
        eventCount: eventCount.value,
      }),
    'og:image': baseUrl + t('general.openGraphImages.default'),
    'og:type': 'website',
    ...(canonicalLink && { 'og:url': canonicalLink }),
  }
  const seoOptions = computed(() => {
    return {
      head: {
        ...(hasTitleTranslation.value && {
          title: t(`general.titles.${routeName.value}`, {
            eventCount: eventCount.value,
          }),
        }),
        titleTemplate: '%s' + ' | ' + t('general.titles.base'),
        htmlAttrs: {
          lang: i18nHead.value.htmlAttrs!.lang,
        },
      },
      meta: customMeta,
    }
  })
  useSeoHelper(seoOptions)
}

export default useLayoutHead
