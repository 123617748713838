import type { CategoryItem, AlgoliaEventItem } from '../types'
import type { GalleryItem } from '~/composables/types/event'
import type { GTMAlgoliaEventItem } from '~/composables/types/checkout'

const fnGetMainCategory = (item: AlgoliaEventItem): string | null => {
  return item?.categories?.[0]?.name || null
}

const fnGetCategories = (item: AlgoliaEventItem): CategoryItem => {
  return item?.categories || null
}

const getPrincipalImage = (item: AlgoliaEventItem): string => {
  if (item?.main_image?.thumb) return item?.main_image?.thumb
  // Fallback thumbnails
  if (item?.photos?.find((image: GalleryItem) => image?.isPrincipal)) {
    return item?.photos?.find((image: GalleryItem) => image?.isPrincipal)?.thumb
  }
  return item?.photos?.[0]?.thumb || ''
}

const getPrincipalAlt = (item: AlgoliaEventItem): string => {
  return item?.main_image?.alt || ''
}

const fnGetPermalink = (item: AlgoliaEventItem): string => {
  return item?.permalink || ''
}

const fnGetPrice = (item: AlgoliaEventItem, prefix = ''): string => {
  let price = item.default_price?.formatted || null

  const dates = item.dates
  if (typeof dates !== 'undefined' && dates.length > 0) {
    const date = dates[0]

    price = date.price?.formatted || null
  }

  return prefix + ' ' + price
}

const getPriceAmount = (item: AlgoliaEventItem): number =>
  parseInt(item?.default_price?.amount || '0')

const getAverageRating = (
  event: AlgoliaEventItem,
  locale = 'de-DE'
): string | number | null =>
  isGiftcardEvent(event)
    ? null
    : event?.rating
      ? new Intl.NumberFormat(locale, {
          maximumSignificantDigits: 2,
          minimumSignificantDigits: 2,
        }).format(event?.rating)
      : 0

const getBadgeLabel = (
  item: AlgoliaEventItem,
  $t: (key: string) => string
): string => {
  if (item.type === 'NATIONWIDE') {
    return $t('product.card.productBox')
  } else if (item.has_mobile_classes) {
    return $t('product.card.mobileClasses')
  }
  return ''
}

const getRatingsAmount = (event: AlgoliaEventItem): number =>
  event?.totalRating || 0

const fnGetDuration = (item: AlgoliaEventItem): number | null => {
  /* Default duration is in minutes */
  const duration = item.default_duration
  if (duration) {
    return duration
  }

  const dates = item.dates
  if (typeof dates !== 'undefined' && dates.length > 0) {
    const date = dates[0]

    /* Duration is in minutes */
    return date.duration
  }

  return null
}
const fnGetTitle = (item: AlgoliaEventItem): string => {
  return item.title
}

const fnGetSubtitle = (item: AlgoliaEventItem): string => {
  return item.subtitle
}

const fnGetNextDateAvailableStart = (
  item: AlgoliaEventItem
): number | undefined => {
  return item?.next_event || null
}

const fnGetNextDateAvailableEnd = (
  item: AlgoliaEventItem
): number | undefined => {
  const dates = item.dates
  if (typeof dates !== 'undefined' && dates.length > 0) {
    const date = dates[0]

    return date.start_date
  }

  return undefined
}

const fnGetType = (
  item: AlgoliaEventItem
): 'HYBRID' | 'IN_PERSON' | 'ONLINE' | 'NATIONWIDE' => {
  return item.type || ''
}

const fnGetMinimumPriceAmount = (item: AlgoliaEventItem): number => {
  return item.min_price
    ? parseInt(item.min_price.amount)
    : parseInt(item.default_price.amount)
}

const fnGetMaximumPriceAmount = (item: AlgoliaEventItem): number => {
  return item.max_price && Number(item.max_price.amount) > 0
    ? parseInt(item.max_price.amount)
    : getPriceAmount(item)
}

const fnGetMinimumPriceCurrency = (item: AlgoliaEventItem): string => {
  return item.min_price ? item.min_price.currency : item.default_price.currency
}

const fnGetMaximumPriceCurrency = (item: AlgoliaEventItem): string => {
  return item.max_price ? item.max_price.currency : item.default_price.currency
}

const fnGetSupplier = (item: AlgoliaEventItem): string => {
  return item?.supplier || ''
}

const getSupplierAlias = (item: AlgoliaEventItem): string | undefined => {
  return item?.supplier_alias || undefined
}

const getSupplierId = (item: AlgoliaEventItem): string | undefined => {
  return item?.supplier_id || undefined
}

const getSupplierPermalink = (item: AlgoliaEventItem): string | undefined => {
  return item?.supplier_permalink || undefined
}

const fnGetCity = (item: AlgoliaEventItem): string => {
  return item.city || ''
}

const fnGetCitySlug = (item: AlgoliaEventItem): string => {
  return item.city_slug || ''
}

const fnGetFormattedAddress = (item: AlgoliaEventItem): string => {
  return item.address?.formatted || null
}

const fnGetFormattedZipcode = (item: AlgoliaEventItem): string => {
  return item?.address?.zipcode || ''
}

const fnGetHashedId = (item: AlgoliaEventItem): string => {
  return item?.hashed_id || item?.id || ''
}

const getId = (item: AlgoliaEventItem): string => {
  return item?.id || ''
}

const getNextDate = (item: AlgoliaEventItem): number | null => {
  const allDates = [
    ...(item?.dates_ts ?? []),
    ...(item?.dates_private_ts ?? []),
  ].sort()
  if (allDates.length === 0) return null
  const now = Date.now()
  const currentTimestampWithoutMilliseconds = ~~(now / 1000)
  const minAdvance = item?.dates?.[0].min_advance ?? 0

  for (const date of allDates) {
    if (date > currentTimestampWithoutMilliseconds + (minAdvance ?? 0) * 60) {
      return date
    }
  }

  return null
}

const isGiftcardEvent = (event: AlgoliaEventItem): boolean =>
  fnGetSupplier(event) === 'konfetti Gutscheine'

const isBoxEvent = (event: AlgoliaEventItem): boolean =>
  event?.type === 'NATIONWIDE'

const hasShippingCosts = (event: AlgoliaEventItem): boolean =>
  event?.has_shipping_costs > 0

const fnGetLink = (
  eventItem: AlgoliaEventItem,
  queryId = null,
  addSupplierLayoutQuery = false
) => {
  let query = null

  let productType
  switch (true) {
    case isGiftcardEvent(eventItem):
      productType = 'giftcard'
      break
    case isBoxEvent(eventItem):
      productType = 'k'
      break
    default:
      productType = 'e'
      break
  }

  let link = `/${productType}/${fnGetPermalink(eventItem)}/`

  if (addSupplierLayoutQuery) {
    query = 'layout=sup'
  }

  if (queryId) {
    query = query ? `${query}&qid=${queryId}` : `qid=${queryId}`
  }

  if (query) {
    link = `${link}?${query}`
  }

  return link
}

const getRouterPushToEvent = (
  algoliaItem: AlgoliaEventItem,
  queryId: string,
  locale: string,
  addSupplierLayoutQuery = false
) => {
  let productRoute
  switch (true) {
    case isGiftcardEvent(algoliaItem):
      productRoute = 'giftcard-special'
      break
    case isBoxEvent(algoliaItem):
      productRoute = 'k-boxPermalink'
      break
    default:
      productRoute = 'e-eventPermalink'
      break
  }
  return {
    name: `${productRoute}___${locale}`,
    query: {
      ...(queryId && { qid: queryId }),
      ...(addSupplierLayoutQuery && { layout: 'sup' }),
    },
    params: {
      special: fnGetPermalink(algoliaItem),
      eventPermalink: fnGetPermalink(algoliaItem),
      boxPermalink: fnGetPermalink(algoliaItem),
      algoliaEventFromPreviousPage: algoliaItem,
    },
  }
}

const getItemsForGtm = (itemList: AlgoliaEventItem): GTMAlgoliaEventItem[] => {
  const length = itemList.length
  const items: GTMAlgoliaEventItem[] = []

  let index = 0

  for (let i = 0; i < length; i++) {
    const item = itemList[i]
    const price = getPriceAmount(item)
    items.push({
      index,
      item_id: fnGetHashedId(item),
      item_name: fnGetTitle(item),
      item_category: fnGetMainCategory(item) || '',
      item_brand: fnGetSupplier(item),
      price: parseFloat(price?.toFixed(2) || price) / 100,
    })
    index++
  }

  return items
}

export const algoliaEventGetters = {
  getPrincipalImage,
  getPrincipalAlt,
  fnGetMainCategory,
  fnGetCategories,
  fnGetPermalink,
  fnGetPrice,
  getPriceAmount,
  getAverageRating,
  getRatingsAmount,
  fnGetDuration,
  fnGetTitle,
  fnGetSubtitle,
  getBadgeLabel,
  fnGetNextDateAvailableStart,
  fnGetNextDateAvailableEnd,
  getNextDate,
  fnGetType,
  fnGetMinimumPriceAmount,
  fnGetMinimumPriceCurrency,
  fnGetSupplier,
  fnGetCity,
  fnGetCitySlug,
  fnGetFormattedAddress,
  fnGetFormattedZipcode,
  fnGetMaximumPriceAmount,
  fnGetMaximumPriceCurrency,
  fnGetHashedId,
  getId,
  fnGetLink,
  isGiftcardEvent,
  isBoxEvent,
  hasShippingCosts,
  getRouterPushToEvent,
  getSupplierPermalink,
  getSupplierAlias,
  getSupplierId,
  getItemsForGtm,
}
