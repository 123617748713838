import type { AsyncData, UseFetchOptions } from 'nuxt/app'
import { FetchError } from 'ofetch'
import * as qs from 'qs'
import type { KeysOf, PickFrom } from '#app/composables/asyncData'
import { useClientSideState } from '~/composables/useClientSideState'

export function useKftApiFetch<T, Y = T>(
  url: string,
  options: UseFetchOptions<T, Y> = {}
):
  | AsyncData<PickFrom<Y, KeysOf<Y>> | null, FetchError<any> | null>
  | undefined {
  const config = useRuntimeConfig()
  const { locale: ISOLocale } = useI18n()
  const locale = convertISO2Locale(ISOLocale.value)
  const localeBCP47 = convertLocaleIntoBCP47(ISOLocale.value)
  const { apiBaseURL } = config.public

  const nuxt = useNuxtApp()

  const activeRequests = useClientSideState<
    Record<
      string,
      AsyncData<PickFrom<Y, KeysOf<Y>> | null, FetchError<any> | null>
    >
  >('useKftApiFetch-active-request', () => ({}))

  const activeRequestsKey =
    options?.key || `${url}?${qs.stringify(options?.query)}`

  if (activeRequests.value[activeRequestsKey]) {
    return activeRequests.value[activeRequestsKey]
  }
  const defaults: UseFetchOptions<T, Y> = {
    baseURL: apiBaseURL,
    // cache request
    key: url,

    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${localeBCP47},${locale}`,
    },
    dedupe: 'cancel',
    onResponse(response) {
      delete activeRequests.value[url]
      const customOnResponse = options.onResponse
      delete options.onResponse

      if (customOnResponse && typeof customOnResponse === 'function') {
        customOnResponse(response)
      }
    },
    getCachedData(key) {
      return nuxt.payload.data[key] || nuxt.static.data[key]
      // return nuxt.isHydrating ? nuxt.payload.data[key] : nuxt.static.data[key]
    },
  }

  const fetchOptions = { ...defaults, ...options }

  const promise = useFetch(url, fetchOptions)

  activeRequests.value[url] = promise

  return promise
}
