<template>
  <div
    class="card-wishlist"
    :class="{ 'card-wishlist--active': isOnWishlist }"
    :title="
      isOnWishlist
        ? t('wishlist.productCard.removeFromWishlistLabel')
        : t('wishlist.productCard.addToWishlistLabel')
    "
  >
    <WishlistIcon />
  </div>
</template>

<script setup lang="ts">
import WishlistIcon from '@/public/icons/wishlist.svg?component'

const { t } = useI18n()

interface Props {
  isOnWishlist?: boolean
}

const { isOnWishlist = false } = defineProps<Props>()
</script>

<style lang="postcss" scoped>
@keyframes heartbeat {
  0%,
  40%,
  80%,
  100% {
    transform: scale(1);
  }
  20%,
  60% {
    transform: scale(1.25);
  }
}

.card-wishlist {
  width: 30px;
  height: 30px;
  right: 8px;
  top: 8px;
  position: absolute;
  cursor: pointer;

  :deep(path) {
    @apply duration-300 ease-in-out;
    fill: transparent;
  }

  &:hover :deep(path) {
    fill: #d1d5db;
  }

  &--active {
    animation: heartbeat 1s forwards;
    animation-timing-function: ease-in-out;

    :deep(path) {
      fill: #f85030;
      stroke: #f85030;
    }
  }
}
</style>
