import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "17",
  height: "16",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { "clip-path": "url(#a)" }, [
      _createElementVNode("path", {
        fill: "#374151",
        d: "M3.94 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
      })
    ], -1),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "a" }, [
        _createElementVNode("path", {
          fill: "#fff",
          d: "M.94 0h16v16h-16z"
        })
      ])
    ], -1)
  ])))
}
export default { render: render }