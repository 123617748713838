<template>
  <Dialog
    :open="isShareModalOpen"
    class="relative z-[100]"
    @close="$emit('close', $event)"
  >
    <!-- The backdrop, rendered as a fixed sibling to the panel container -->
    <div class="fixed inset-0 bg-black/30" aria-hidden="true" />
    <div class="fixed inset-0 flex w-screen items-center justify-center p-4">
      <DialogPanel class="w-full max-w-md rounded-xl bg-white">
        <DialogTitle class="p-4 text-base font-medium">
          <div class="flex">
            <span>
              {{
                $t(
                  isGiftcard
                    ? $t('product.gallery.shareModal.shareGiftcardTitle')
                    : $t('product.gallery.shareModal.shareEventTitle')
                )
              }}
            </span>
            <Button
              color="tertiary"
              class="ml-auto shadow-none p-0 bg-transparent"
              @click.prevent="$emit('close', $event)"
            >
              <span
                class="inline-block text-3xl leading-3 font-thin rotate-45"
                @click="$emit('navbarClose')"
                >+</span
              >
            </Button>
          </div>
        </DialogTitle>
        <DialogDescription class="h-4/5 p-4 overflow-x-auto">
          <div v-for="item in shareButtonsData" :key="item.type">
            <Button
              class="bg-white hover:bg-gray-50 text-stone-900 border border-gray-100 w-full mb-2 shadow-none flex justify-between"
              @click="handleShareButtonAction(item.type, item.link)"
            >
              {{ item?.label }}
              <component :is="item.icon" class="w-6 h-6" />
            </Button>
          </div>
        </DialogDescription>
      </DialogPanel>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue'
import IconCopy from '~/components/icons/IconCopy'
import IconFacebook from '~/components/icons/IconFacebook'
import IconEmail from '~/components/icons/IconEmail'
import IconWhatsapp from '~/components/icons/IconWhatsapp'
const { t } = useI18n()

const props = defineProps<{
  isShareModalOpen: boolean
  isGiftcard?: boolean
  pageTitle: string
  link: string
}>()

const shareButtonsData = [
  {
    type: 'copy_link',
    label: t('product.gallery.shareModal.copyLinkButton'),
    copiedLinkTranslationKey: t('product.gallery.shareModal.copiedLinkButton'),
    icon: IconCopy,
  },
  {
    type: 'email',
    label: 'Email',
    link: `mailto:?subject=${
      props.isGiftcard
        ? t('product.gallery.shareModal.shareGiftcardSubject')
        : t('product.gallery.shareModal.shareEventSubject')
    }: ${props.pageTitle}&body=${
      props.isGiftcard
        ? t('product.gallery.shareModal.shareGiftcardMessage')
        : t('product.gallery.shareModal.shareEventMessage')
    }: ${props.link}`,
    icon: IconEmail,
  },
  {
    type: 'whatsapp',
    label: 'Whatsapp',
    link: `whatsapp://send?text=${
      props.isGiftcard
        ? t('product.gallery.shareModal.shareGiftcardMessage')
        : t('product.gallery.shareModal.shareEventMessage')
    }: ${props.link}`,
    icon: IconWhatsapp,
  },
  {
    type: 'facebook',
    label: 'Facebook',
    link: `https://www.facebook.com/sharer.php?u=${props.link}`,
    icon: IconFacebook,
  },
]

const emit = defineEmits(['close', 'shared'])
const linkCopied = ref(false)

const handleClickCopyEventLinkToClipboard = () => {
  linkCopied.value = true

  let link = window?.location.href

  if (props.link) link = props.link

  const textArea = document.createElement('textarea')
  textArea.value = `${link}?utm_source=konfetti&utm_medium=share_event&utm_campaign=copy_link`
  document.body.appendChild(textArea)
  textArea.focus({ preventScroll: true })
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) {
    console.error('Unable to copy to clipboard', err)
  }

  textArea.remove()

  setTimeout(() => {
    linkCopied.value = false
  }, 2000)
}
const handleShareButtonAction = (type: string, link?: string) => {
  emit('shared', type)
  if (type === 'copy_link') {
    handleClickCopyEventLinkToClipboard()
  } else {
    window.open(link, '_blank')
  }
}
</script>
