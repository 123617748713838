<template>
  <div>
    <NuxtLazyHydrate when-idle>
      <DateRequest />
    </NuxtLazyHydrate>
    <NuxtLazyHydrate when-visible>
      <EventSubscribeToUpdates />
    </NuxtLazyHydrate>
    <WishlistSidebar />
  </div>
</template>

<script setup lang="ts"></script>
