<template>
  <NuxtErrorBoundary @error="handleError">
    <slot />
  </NuxtErrorBoundary>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()

const handleError = (e: Error) => {
  const failedToImportErrors = [
    'Importing a module script failed',
    'Failed to fetch dynamically imported module',
    'Failed to fetch',
  ]

  const isFailedToImportError = ref(false)

  failedToImportErrors.forEach((errMessage) => {
    if (e?.message?.includes(errMessage)) {
      window?.location?.reload()
      isFailedToImportError.value = true
    }
  })

  if (config.public.appEnv !== 'production' && !isFailedToImportError.value) {
    console.error(e)
  }
}
</script>
