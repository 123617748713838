<template>
  <div
    ref="container"
    class="wrapper relative block w-full"
    :class="isHorizontal ? 'products-loader-horizontal' : ''"
  >
    <Transition
      name="fade"
      mode="out-in"
      @before-leave="() => setHeight"
      @enter="() => setHeight"
      @after-leave="resetHeight"
    >
      <PageLoaderEventList
        key="events-skeleton"
        :is-horizontal-list="isHorizontal"
      />
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

withDefaults(
  defineProps<{
    isHorizontal?: boolean
  }>(),
  {
    isHorizontal: false,
  }
)

const container = ref<HTMLElement | null>(null)

const setHeight = (element: Element) => {
  if (container.value) {
    const htmlElement = element as HTMLElement
    container.value.style.minHeight = `${htmlElement.offsetHeight}px`
  }
}

const resetHeight = () => {
  if (container.value) {
    container.value.style.minHeight = '1px'
  }
}
</script>

<style lang="postcss" scoped>
.wrapper {
  transition: height 0.3s ease;
  min-height: 510px;

  &.products-loader-horizontal {
    min-height: 255px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
  will-change: opacity, transform;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  transform: translateY(0px);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-from {
  transform: translateY(50px);
}
</style>
