import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_eslint@8.57.1_rollup@4.30.1_typescript@5.7.2_vite@6.0.7_vue-tsc@2.2.0/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/app/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}