<template>
  <div class="flex gap-2 sm:gap-4">
    <Menu>
      <div class="relative flex">
        <client-only>
          <MenuButton v-if="!hideAccountIcon" aria-label="user menu">
            <div class="relative flex items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-person-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
                />
              </svg>
              <span class="hidden sm:block">{{ $t('general.account') }}</span>
            </div>
          </MenuButton>
          <MenuItems
            class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 absolute right-0"
          >
            <div class="py-2 text-sm text-gray-500">
              <MenuItem
                v-slot="{ active }"
                class="block px-4 py-2 hover:bg-gray-100"
              >
                <button
                  :class="{ 'bg-blue-500': active }"
                  class="flex align-middle gap-2 w-full"
                  @click="$router.push(localePath('/account'))"
                >
                  <IconSettings size="16" />
                  {{ $t('general.account') }}
                </button>
              </MenuItem>
              <MenuItem
                v-if="isAuthenticated"
                v-slot="{ active }"
                class="block px-4 py-2 hover:bg-gray-100"
              >
                <button
                  :class="{ 'bg-blue-500': active }"
                  class="flex align-middle gap-2 w-full"
                  to="/account-settings"
                  @click.prevent="handleClickLogOut"
                >
                  <IconBoxArrowLeft size="16" />

                  {{ $t('general.logout') }}
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </client-only>
      </div>
    </Menu>
    <div
      v-show="wishlist.items.length > 0"
      class="relative flex items-center gap-1 cursor-pointer"
      data-test-id="navbar-cart-icon"
      @click="() => toggleWishlistSidebar(true)"
    >
      <SuitHeartFill class="wishlist-icon" />
      <span class="hidden sm:block">{{ $t('general.wishlist') }}</span>
    </div>
    <div
      class="relative flex items-center gap-1 cursor-pointer"
      data-test-id="navbar-cart-icon"
      @click="toggleCartSidebar(true)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="currentColor"
        class="cursor-pointer hover:scale-110 transition-all ease duration-200"
        viewBox="0 0 16 16"
      >
        <path
          d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"
        />
      </svg>
      <span class="hidden sm:block">{{
        $t('general.bottomNavigationCart')
      }}</span>
      <client-only>
        <div v-if="cartGetters.getTotalItems(cart) > 0" class="cart-badge">
          {{ cartGetters.getTotalItems(cart) }}
        </div>
      </client-only>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  provideUseId,
} from '@headlessui/vue'
import IconBoxArrowLeft from '~/components/icons/IconBoxArrowLeft'
import IconSettings from '~/components/icons/IconSettings'
import SuitHeartFill from '@/public/icons/suit-heart-fill.svg?component'

const { isAuthenticated, logOut } = useAuth()
const { toggleCartSidebar } = useUiState()
const localePath = useLocalePath()

const { wishlist } = useWishlist()
const { toggle: toggleWishlistSidebar } = useWishlistSidebarUiState()

const { hideAccountIcon } = defineProps({
  hideAccountIcon: {
    type: Boolean,
    default: false,
  },
})

const { cart } = useCartWithSubdomainLogic()

const handleClickLogOut = () => {
  logOut()
}

// This is a workaround for the headlessui bug where MenuButton
// causes a warning in the console about hydration mismatch.
// https://github.com/tailwindlabs/headlessui/issues/2913
provideUseId(() => useId())
</script>
<style>
.cart-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  min-width: 1rem;
  min-height: 1rem;
  padding: 2px 3px 2px 5px;
  border-radius: 9999px;
  @apply bg-red text-white;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.75rem;
}
</style>

<style lang="postcss" scoped>
.wishlist-icon {
  :deep(path) {
    @apply duration-300 ease-in-out;
    fill: #000;
  }

  &:hover :deep(path) {
    fill: #f85030;
  }
}
</style>
