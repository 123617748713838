
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91category_93fP1UDIM9SzMeta } from "/app/pages/[city]/[category].vue?macro=true";
import { default as indexKGmngfNLxoMeta } from "/app/pages/[city]/index.vue?macro=true";
import { default as _91slug_93wvZUvdb0ufMeta } from "/app/pages/b2b/[slug].vue?macro=true";
import { default as employee_45giftsqTa1K5eNvOMeta } from "/app/pages/b2b/employee-gifts.vue?macro=true";
import { default as mitarbeitergeschenkeSC5nsF1pKhMeta } from "/app/pages/b2b/mitarbeitergeschenke.vue?macro=true";
import { default as _91slug_93s3Ah3O360WMeta } from "/app/pages/b2c/[slug].vue?macro=true";
import { default as _91category_93RsmnoccMusMeta } from "/app/pages/c/[category].vue?macro=true";
import { default as _91eventPermalink_938FHttEhT95Meta } from "/app/pages/e/[eventPermalink].vue?macro=true";
import { default as _91special_93XkNi6MGLmNMeta } from "/app/pages/giftcard/[special].vue?macro=true";
import { default as index2MdHjQMY2vMeta } from "/app/pages/giftcard/index.vue?macro=true";
import { default as impressumLDkFRkkSAmMeta } from "/app/pages/impressum.vue?macro=true";
import { default as _91boxPermalink_93UlOzvsdNqbMeta } from "/app/pages/k/[boxPermalink].vue?macro=true";
import { default as kitsPa7ZoKDFdmMeta } from "/app/pages/kits.vue?macro=true";
import { default as _91articleSlug_93coq2vIBmbxMeta } from "/app/pages/magazine/[articleSlug].vue?macro=true";
import { default as indexbOlQycfl1jMeta } from "/app/pages/magazine/index.vue?macro=true";
import { default as _91partnerPermalink_933gAMGc3SOyMeta } from "/app/pages/p/[partnerPermalink].vue?macro=true";
import { default as indexu3F4xqa36oMeta } from "/app/pages/partner/index.vue?macro=true";
import { default as ping3fIchpdHbLMeta } from "/app/pages/ping.vue?macro=true";
import { default as _91previewPermalink_93S4fcYsFG5KMeta } from "/app/pages/preview/[previewPermalink].vue?macro=true";
import { default as privacy_45policyOPxqa3IsSYMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as giftcard6cV5nquJmzMeta } from "/app/pages/sub-domain/giftcard.vue?macro=true";
import { default as indexMlk7ddS01FMeta } from "/app/pages/sub-domain/index.vue?macro=true";
import { default as _91slug_93aE6F2v7XKKMeta } from "/app/pages/t/[slug].vue?macro=true";
import { default as _91city_93u20gt0fdsoMeta } from "/app/pages/teamevent/[city].vue?macro=true";
import { default as index9VhG5wjDcIMeta } from "/app/pages/teamevent/index.vue?macro=true";
import { default as terms_45and_45conditionszK6eZNsNEXMeta } from "/app/pages/terms-and-conditions.vue?macro=true";
import { default as all_45widgetsg9TNAhwbamMeta } from "/app/pages/widgets/all-widgets.vue?macro=true";
import { default as _91partnerId_93rFbWgaPPp3Meta } from "/app/pages/widgets/badge/[partnerId].vue?macro=true";
import { default as _91partnerId_93PMP8ookq28Meta } from "/app/pages/widgets/general-banner/[partnerId].vue?macro=true";
import { default as _91partnerId_93uFYqsFaqWFMeta } from "/app/pages/widgets/partner-banner/[partnerId].vue?macro=true";
import { default as _91eventId_931Tqc6oNEHXMeta } from "/app/pages/widgets/reviews-by-event-id/[eventId].vue?macro=true";
import { default as _91partnerId_93cyDdldkbBQMeta } from "/app/pages/widgets/reviews-by-partner-id/[partnerId].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "city-category___en-us",
    path: "/en-us/:city()/:category()/",
    component: () => import("/app/pages/[city]/[category].vue")
  },
  {
    name: "city-category___de-de",
    path: "/de-de/:city()/:category()/",
    component: () => import("/app/pages/[city]/[category].vue")
  },
  {
    name: "city___en-us",
    path: "/en-us/:city()/",
    component: () => import("/app/pages/[city]/index.vue")
  },
  {
    name: "city___de-de",
    path: "/de-de/:city()/",
    component: () => import("/app/pages/[city]/index.vue")
  },
  {
    name: "b2b-slug___en-us",
    path: "/en-us/b2b/:slug()/",
    meta: _91slug_93wvZUvdb0ufMeta || {},
    component: () => import("/app/pages/b2b/[slug].vue")
  },
  {
    name: "b2b-slug___de-de",
    path: "/de-de/b2b/:slug()/",
    meta: _91slug_93wvZUvdb0ufMeta || {},
    component: () => import("/app/pages/b2b/[slug].vue")
  },
  {
    name: "b2b-employee-gifts___en-us",
    path: "/en-us/b2b/employee-gifts/",
    meta: employee_45giftsqTa1K5eNvOMeta || {},
    component: () => import("/app/pages/b2b/employee-gifts.vue")
  },
  {
    name: "b2b-employee-gifts___de-de",
    path: "/de-de/b2b/employee-gifts/",
    meta: employee_45giftsqTa1K5eNvOMeta || {},
    component: () => import("/app/pages/b2b/employee-gifts.vue")
  },
  {
    name: "b2b-mitarbeitergeschenke___en-us",
    path: "/en-us/b2b/mitarbeitergeschenke/",
    meta: mitarbeitergeschenkeSC5nsF1pKhMeta || {},
    component: () => import("/app/pages/b2b/mitarbeitergeschenke.vue")
  },
  {
    name: "b2b-mitarbeitergeschenke___de-de",
    path: "/de-de/b2b/mitarbeitergeschenke/",
    meta: mitarbeitergeschenkeSC5nsF1pKhMeta || {},
    component: () => import("/app/pages/b2b/mitarbeitergeschenke.vue")
  },
  {
    name: "b2c-slug___en-us",
    path: "/en-us/b2c/:slug()/",
    meta: _91slug_93s3Ah3O360WMeta || {},
    component: () => import("/app/pages/b2c/[slug].vue")
  },
  {
    name: "b2c-slug___de-de",
    path: "/de-de/b2c/:slug()/",
    meta: _91slug_93s3Ah3O360WMeta || {},
    component: () => import("/app/pages/b2c/[slug].vue")
  },
  {
    name: "c-category___en-us",
    path: "/en-us/c/:category()/",
    meta: _91category_93RsmnoccMusMeta || {},
    component: () => import("/app/pages/c/[category].vue")
  },
  {
    name: "c-category___de-de",
    path: "/de-de/c/:category()/",
    meta: _91category_93RsmnoccMusMeta || {},
    component: () => import("/app/pages/c/[category].vue")
  },
  {
    name: "e-eventPermalink___en-us",
    path: "/en-us/e/:eventPermalink()/",
    meta: _91eventPermalink_938FHttEhT95Meta || {},
    component: () => import("/app/pages/e/[eventPermalink].vue")
  },
  {
    name: "e-eventPermalink___de-de",
    path: "/de-de/e/:eventPermalink()/",
    meta: _91eventPermalink_938FHttEhT95Meta || {},
    component: () => import("/app/pages/e/[eventPermalink].vue")
  },
  {
    name: "giftcard-special___en-us",
    path: "/en-us/giftcard/:special()/",
    meta: _91special_93XkNi6MGLmNMeta || {},
    component: () => import("/app/pages/giftcard/[special].vue")
  },
  {
    name: "giftcard-special___de-de",
    path: "/de-de/giftcard/:special()/",
    meta: _91special_93XkNi6MGLmNMeta || {},
    component: () => import("/app/pages/giftcard/[special].vue")
  },
  {
    name: "giftcard___en-us",
    path: "/en-us/giftcard/",
    meta: index2MdHjQMY2vMeta || {},
    component: () => import("/app/pages/giftcard/index.vue")
  },
  {
    name: "giftcard___de-de",
    path: "/de-de/giftcard/",
    meta: index2MdHjQMY2vMeta || {},
    component: () => import("/app/pages/giftcard/index.vue")
  },
  {
    name: "impressum___en-us",
    path: "/en-us/impressum/",
    meta: impressumLDkFRkkSAmMeta || {},
    component: () => import("/app/pages/impressum.vue")
  },
  {
    name: "impressum___de-de",
    path: "/de-de/impressum/",
    meta: impressumLDkFRkkSAmMeta || {},
    component: () => import("/app/pages/impressum.vue")
  },
  {
    name: "index___en-us",
    path: "/en-us/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___de-de",
    path: "/de-de/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "k-boxPermalink___en-us",
    path: "/en-us/k/:boxPermalink()/",
    meta: _91boxPermalink_93UlOzvsdNqbMeta || {},
    component: () => import("/app/pages/k/[boxPermalink].vue")
  },
  {
    name: "k-boxPermalink___de-de",
    path: "/de-de/k/:boxPermalink()/",
    meta: _91boxPermalink_93UlOzvsdNqbMeta || {},
    component: () => import("/app/pages/k/[boxPermalink].vue")
  },
  {
    name: "kits___en-us",
    path: "/en-us/kits/",
    meta: kitsPa7ZoKDFdmMeta || {},
    component: () => import("/app/pages/kits.vue")
  },
  {
    name: "kits___de-de",
    path: "/de-de/kits/",
    meta: kitsPa7ZoKDFdmMeta || {},
    component: () => import("/app/pages/kits.vue")
  },
  {
    name: "magazine-articleSlug___en-us",
    path: "/en-us/magazine/:articleSlug()/",
    meta: _91articleSlug_93coq2vIBmbxMeta || {},
    component: () => import("/app/pages/magazine/[articleSlug].vue")
  },
  {
    name: "magazine-articleSlug___de-de",
    path: "/de-de/magazine/:articleSlug()/",
    meta: _91articleSlug_93coq2vIBmbxMeta || {},
    component: () => import("/app/pages/magazine/[articleSlug].vue")
  },
  {
    name: "magazine___en-us",
    path: "/en-us/magazine/",
    meta: indexbOlQycfl1jMeta || {},
    component: () => import("/app/pages/magazine/index.vue")
  },
  {
    name: "magazine___de-de",
    path: "/de-de/magazine/",
    meta: indexbOlQycfl1jMeta || {},
    component: () => import("/app/pages/magazine/index.vue")
  },
  {
    name: "p-partnerPermalink___en-us",
    path: "/en-us/p/:partnerPermalink()/",
    meta: _91partnerPermalink_933gAMGc3SOyMeta || {},
    component: () => import("/app/pages/p/[partnerPermalink].vue")
  },
  {
    name: "p-partnerPermalink___de-de",
    path: "/de-de/p/:partnerPermalink()/",
    meta: _91partnerPermalink_933gAMGc3SOyMeta || {},
    component: () => import("/app/pages/p/[partnerPermalink].vue")
  },
  {
    name: "partner___en-us",
    path: "/en-us/partner/",
    meta: indexu3F4xqa36oMeta || {},
    component: () => import("/app/pages/partner/index.vue")
  },
  {
    name: "partner___de-de",
    path: "/de-de/partner/",
    meta: indexu3F4xqa36oMeta || {},
    component: () => import("/app/pages/partner/index.vue")
  },
  {
    name: "ping___en-us",
    path: "/en-us/ping/",
    component: () => import("/app/pages/ping.vue")
  },
  {
    name: "ping___de-de",
    path: "/de-de/ping/",
    component: () => import("/app/pages/ping.vue")
  },
  {
    name: "preview-previewPermalink___en-us",
    path: "/en-us/preview/:previewPermalink()/",
    meta: _91previewPermalink_93S4fcYsFG5KMeta || {},
    component: () => import("/app/pages/preview/[previewPermalink].vue")
  },
  {
    name: "preview-previewPermalink___de-de",
    path: "/de-de/preview/:previewPermalink()/",
    meta: _91previewPermalink_93S4fcYsFG5KMeta || {},
    component: () => import("/app/pages/preview/[previewPermalink].vue")
  },
  {
    name: "privacy-policy___en-us",
    path: "/en-us/privacy-policy/",
    component: () => import("/app/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___de-de",
    path: "/de-de/privacy-policy/",
    component: () => import("/app/pages/privacy-policy.vue")
  },
  {
    name: "search___en-us",
    path: "/en-us/search/",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___de-de",
    path: "/de-de/search/",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "sub-domain-giftcard___en-us",
    path: "/en-us/sub-domain/giftcard/",
    meta: giftcard6cV5nquJmzMeta || {},
    component: () => import("/app/pages/sub-domain/giftcard.vue")
  },
  {
    name: "sub-domain-giftcard___de-de",
    path: "/de-de/sub-domain/giftcard/",
    meta: giftcard6cV5nquJmzMeta || {},
    component: () => import("/app/pages/sub-domain/giftcard.vue")
  },
  {
    name: "sub-domain___en-us",
    path: "/en-us/sub-domain/",
    meta: indexMlk7ddS01FMeta || {},
    component: () => import("/app/pages/sub-domain/index.vue")
  },
  {
    name: "sub-domain___de-de",
    path: "/de-de/sub-domain/",
    meta: indexMlk7ddS01FMeta || {},
    component: () => import("/app/pages/sub-domain/index.vue")
  },
  {
    name: "t-slug___en-us",
    path: "/en-us/t/:slug()/",
    meta: _91slug_93aE6F2v7XKKMeta || {},
    component: () => import("/app/pages/t/[slug].vue")
  },
  {
    name: "t-slug___de-de",
    path: "/de-de/t/:slug()/",
    meta: _91slug_93aE6F2v7XKKMeta || {},
    component: () => import("/app/pages/t/[slug].vue")
  },
  {
    name: "teamevent-city___en-us",
    path: "/en-us/teamevent/:city()/",
    meta: _91city_93u20gt0fdsoMeta || {},
    component: () => import("/app/pages/teamevent/[city].vue")
  },
  {
    name: "teamevent-city___de-de",
    path: "/de-de/teamevent/:city()/",
    meta: _91city_93u20gt0fdsoMeta || {},
    component: () => import("/app/pages/teamevent/[city].vue")
  },
  {
    name: "teamevent___en-us",
    path: "/en-us/teamevent/",
    meta: index9VhG5wjDcIMeta || {},
    component: () => import("/app/pages/teamevent/index.vue")
  },
  {
    name: "teamevent___de-de",
    path: "/de-de/teamevent/",
    meta: index9VhG5wjDcIMeta || {},
    component: () => import("/app/pages/teamevent/index.vue")
  },
  {
    name: "terms-and-conditions___en-us",
    path: "/en-us/terms-and-conditions/",
    meta: terms_45and_45conditionszK6eZNsNEXMeta || {},
    component: () => import("/app/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___de-de",
    path: "/de-de/terms-and-conditions/",
    meta: terms_45and_45conditionszK6eZNsNEXMeta || {},
    component: () => import("/app/pages/terms-and-conditions.vue")
  },
  {
    name: "widgets-all-widgets___en-us",
    path: "/en-us/widgets/all-widgets/",
    meta: all_45widgetsg9TNAhwbamMeta || {},
    component: () => import("/app/pages/widgets/all-widgets.vue")
  },
  {
    name: "widgets-all-widgets___de-de",
    path: "/de-de/widgets/all-widgets/",
    meta: all_45widgetsg9TNAhwbamMeta || {},
    component: () => import("/app/pages/widgets/all-widgets.vue")
  },
  {
    name: "widgets-badge-partnerId___en-us",
    path: "/en-us/widgets/badge/:partnerId()/",
    meta: _91partnerId_93rFbWgaPPp3Meta || {},
    component: () => import("/app/pages/widgets/badge/[partnerId].vue")
  },
  {
    name: "widgets-badge-partnerId___de-de",
    path: "/de-de/widgets/badge/:partnerId()/",
    meta: _91partnerId_93rFbWgaPPp3Meta || {},
    component: () => import("/app/pages/widgets/badge/[partnerId].vue")
  },
  {
    name: "widgets-general-banner-partnerId___en-us",
    path: "/en-us/widgets/general-banner/:partnerId()/",
    meta: _91partnerId_93PMP8ookq28Meta || {},
    component: () => import("/app/pages/widgets/general-banner/[partnerId].vue")
  },
  {
    name: "widgets-general-banner-partnerId___de-de",
    path: "/de-de/widgets/general-banner/:partnerId()/",
    meta: _91partnerId_93PMP8ookq28Meta || {},
    component: () => import("/app/pages/widgets/general-banner/[partnerId].vue")
  },
  {
    name: "widgets-partner-banner-partnerId___en-us",
    path: "/en-us/widgets/partner-banner/:partnerId()/",
    meta: _91partnerId_93uFYqsFaqWFMeta || {},
    component: () => import("/app/pages/widgets/partner-banner/[partnerId].vue")
  },
  {
    name: "widgets-partner-banner-partnerId___de-de",
    path: "/de-de/widgets/partner-banner/:partnerId()/",
    meta: _91partnerId_93uFYqsFaqWFMeta || {},
    component: () => import("/app/pages/widgets/partner-banner/[partnerId].vue")
  },
  {
    name: "widgets-reviews-by-event-id-eventId___en-us",
    path: "/en-us/widgets/reviews-by-event-id/:eventId()/",
    meta: _91eventId_931Tqc6oNEHXMeta || {},
    component: () => import("/app/pages/widgets/reviews-by-event-id/[eventId].vue")
  },
  {
    name: "widgets-reviews-by-event-id-eventId___de-de",
    path: "/de-de/widgets/reviews-by-event-id/:eventId()/",
    meta: _91eventId_931Tqc6oNEHXMeta || {},
    component: () => import("/app/pages/widgets/reviews-by-event-id/[eventId].vue")
  },
  {
    name: "widgets-reviews-by-partner-id-partnerId___en-us",
    path: "/en-us/widgets/reviews-by-partner-id/:partnerId()/",
    meta: _91partnerId_93cyDdldkbBQMeta || {},
    component: () => import("/app/pages/widgets/reviews-by-partner-id/[partnerId].vue")
  },
  {
    name: "widgets-reviews-by-partner-id-partnerId___de-de",
    path: "/de-de/widgets/reviews-by-partner-id/:partnerId()/",
    meta: _91partnerId_93cyDdldkbBQMeta || {},
    component: () => import("/app/pages/widgets/reviews-by-partner-id/[partnerId].vue")
  }
]